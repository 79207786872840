.topbarContainer {
    height: 80px;
    width: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    color: white;
}

.topbarLeft {
    flex: 3;
}

.logo {
    font-size: 72px;
    font-weight: bold;
}

.topbarCenter {
    flex: 5;
    color: brown;
    text-align: center;
}

.topbarRight {
    flex: 3;
}