.mainContainer {
    background-color: black;
    color: white;
    width: 100%;
    height: calc(100vh - 80px)
}

.ressourceContainer {
    background-color: black;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    color: white;
    margin: 3px;
}
.ressource {
    display: flex;
}

.ressourceStatus1 {
    flex: 0.5;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    background-color: forestgreen;
    padding: 2px;
    text-align: center;
}

.ressourceStatus2 {
    flex: 0.5;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    padding: 2px;
    text-align: center;
}

.ressourceStatus3 {
    flex: 0.5;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    background-color: yellow;
    padding: 2px;
    text-align: center;
    color: black;
}

.ressourceStatus4 {
    flex: 0.5;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    background-color: red;
    padding: 2px;
    text-align: center;
    color: white;
}

.ressourceStatus6 {
    flex: 0.5;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    background-color: gray;
    padding: 2px;
    text-align: center;
    color: white;
}

.ressourceName {
    flex:2;
    margin-left: 5px;
    margin-top: 3px;
}